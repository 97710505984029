<template>
    <form autocomplete="off" class="group-form">
        <div class="row g-3">
             <div class="col-12">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="">Status</label>
                <select class="form-select" v-model="data.status" name="status"
                 placeholder="--select status--"
                 :class="{ 'is-invalid': v$.data.status.$error }">
                    <option disabled value="" key="-1">-- select status--</option>
                    <option :value="status.value" 
                    v-for="(status, index) in statusList" :key="index">{{status.label}}</option>
                </select>
                <div v-for="(item, index) in v$.data.status.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Description</label>
                <textarea v-model="data.description" class="form-control"
                rows="3" placeholder="Write Description" name="description"></textarea>
            </div>
            <div class="col-12">
                <vue-dropzone id="groupLogoUploadDrop" ref="groupLogoUploadDrop"
                :use-custom-slot="true" :maxFiles="1">
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> Update group logo </h6>
                    <span class="text-muted fs-13">
                        (Recommended dimension (450x450 pixels))
                    </span>
                </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3">
                    <button @click="resetForm()" class="btn btn-danger me-2" type="button"> Cancel </button>
                    <button class="btn btn-primary" @click.prevent="update()" type="button">Update item</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueDropzone from "../VueDropzone.vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            data: {
                name: "",
                status: "",
                description: "",
            },
            statusList:[
                {label: "Pending", value:"pending"},
                {label: "Approved", value:"approved"},
            ]
        }
    },
    validations: {
        data:{
            name: {required: required},
            status: {required: required}
        }
    },
    methods:{
        update(){
            this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                 if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/groups/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updatedItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.groupLogoUploadDrop){
                arrafile = [...this.$refs.groupLogoUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
}
</script>


<style scoped>
.group-form .category-item{
    border: 2px solid transparent;
}
.group-form .category-item.active{
    border: 2px solid var(--bs-primary);
}
</style>